var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","icon":"","color":"primary"},on:{"click":_vm.close}},[_c('v-icon',[_vm._v(" mdi-close ")])],1),_c('h3',[_vm._v("Quotes")])],1),_c('v-divider',{staticClass:"ml-4 mr-5"}),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0 mt-6",attrs:{"headers":_vm.headers,"items":_vm.item.quotes,"mobile-breakpoint":0,"loading":_vm.loading,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:`item.date`,fn:function({
                    item,
                }){return [_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(_vm.formatDate( item.createdOn.seconds || item.createdOn._seconds ))+" ")])]}},{key:`item.select`,fn:function({
                    item,
                }){return [_c('v-simple-checkbox',{attrs:{"color":"success","disabled":true},model:{value:(item.able),callback:function ($$v) {_vm.$set(item, "able", $$v)},expression:"item.able"}})]}},{key:`item.receivedBy`,fn:function({
                    item,
                }){return [_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.receivedBy.name)+" ")])]}},{key:`item.supplier`,fn:function({
                    item,
                }){return [_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.supplier.name)+" ")])]}},{key:`item.contact`,fn:function({
                    item,
                }){return [_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.contact.name)+" ")])]}},{key:`item.price`,fn:function({
                    item,
                }){return [_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(_vm.numberFormat(item.price))+" ")])]}},{key:`item.days`,fn:function({
                    item,
                }){return [_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(item.days)+" days")])]}},{key:`item.dueDate`,fn:function({
                    item,
                }){return [_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.dueDate ? _vm.formatDate2( item.dueDate.seconds || item.dueDate._seconds ) : '')+" ")])]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }