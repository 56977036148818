<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>{{ createForm ? 'Add New Quote' : 'Update Quote' }}</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid">
                <v-row no-gutters class="my-4">
                    <v-col cols="12">
                        <v-autocomplete
                            v-if="!quote.contacts"
                            v-model="quote.supplier"
                            hide-details
                            label="Supplier"
                            prepend-icon="mdi-account-box-outline"
                            :items="suppliers"
                            item-text="name"
                            item-value="id"
                            :rules="[rules.required]"
                            required
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-4">
                    <v-col cols="12">
                        <v-autocomplete
                            v-if="!quote.contacts"
                            v-model="quote.contact"
                            hide-details
                            label="Contact"
                            :items="contacts"
                            prepend-icon="mdi-account-tie"
                            :disabled="!quote.supplier"
                            item-text="name"
                            item-value="id"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-4">
                    <v-col cols="12">
                        <v-text-field
                            v-if="!quote.contacts"
                            v-model="quote.price"
                            hide-details
                            label="Price"
                            type="number"
                            prepend-icon="mdi-currency-usd"
                            :rules="[rules.required, rules.number]"
                            required
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-4">
                    <v-col cols="12">
                        <v-text-field
                            v-if="!quote.contacts"
                            v-model="quote.days"
                            hide-details
                            label="Time in days"
                            type="number"
                            prepend-icon="mdi-calendar"
                            :rules="[rules.required, rules.number]"
                            required
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mb-2 mt-n5" ref="notes">
                    <v-col cols="12">
                        <v-textarea
                            v-model="quote.notes"
                            rows="2"
                            hide-details
                            label="Notes"
                            prepend-icon="mdi-text"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                rounded
                color="primary"
                :loading="loading"
                :disabled="!valid && createForm"
                class="mb-5 mt-n2"
                @click="save"
            >
                {{ createForm ? 'ADD QUOTE' : 'UPDATE' }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'
import { objDiff } from '@/helpers/objDiff.js'

export default {
    name: 'AddNewQuote',
    props: {
        quote: {
            type: Object,
            default: () => {},
        },
        originalQuote: {
            type: Object,
            default: () => {},
        },
        purchaseId: {
            type: String,
            default: () => undefined,
        },
        itemId: {
            type: String,
            default: () => undefined,
        },
        createForm: {
            type: Boolean,
            default: () => true,
        },
    },
    data: () => ({
        loading: false,
        menu: false,
        valid: false,
        suppliers: [],
        contacts: [],
        files: undefined,
        companyId: JSON.parse(localStorage.getItem('company')),
        rules: {
            required: v => !!v || 'The value is required',
            number: v => v > 0 || 'The value is invalid',
        },
    }),
    async mounted() {
        try {
            this.loading = true
            this.suppliers = await API.getSuppliers()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    computed: {
        quoteDiff() {
            if (!this.createForm) {
                return objDiff(this.originalQuote, this.quote)
            } else {
                return null
            }
        },
    },
    watch: {
        'quote.supplier': async function() {
            try {
                this.loading = true
                this.contacts = await API.getContactsBySupplier({
                    supplierId:
                        typeof this.quote.supplier == 'string'
                            ? this.quote.supplier
                            : this.quote.supplier.id,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        close() {
            this.$emit('closeDialog')
        },
        async save() {
            if (this.createForm) {
                await this.addQuote()
            } else {
                await this.updateQuote()
            }
        },
        async addQuote() {
            try {
                this.loading = true
                const quote = await API.addQuoteToRequestsItem({
                    requestId: this.purchaseId,
                    itemId: this.itemId,
                    supplier:
                        typeof this.quote.supplier == 'string'
                            ? this.quote.supplier
                            : this.quote.supplier.id,
                    contact: this.quote.contact
                        ? typeof this.quote.contact == 'string'
                            ? this.quote.contact
                            : this.quote.contact.id
                        : ' ',

                    price: Number(this.quote.price),
                    days: Number(this.quote.days),
                    notes: this.quote.notes,
                })
                this.$emit('addQuote', this.itemId, quote)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async updateQuote() {
            try {
                this.loading = true
                const paramsToupdate = {
                    requestId: this.purchaseId,
                    itemId: this.itemId,
                }
                if (this.quoteDiff.supplier) {
                    paramsToupdate.supplier =
                        typeof this.quoteDiff.supplier == 'string'
                            ? this.quoteDiff.supplier
                            : this.quoteDiff.supplier.id
                }
                if (this.quoteDiff.contact) {
                    paramsToupdate.contact =
                        typeof this.quoteDiff.contact == 'string'
                            ? this.quoteDiff.contact
                            : this.quoteDiff.contact.id
                }
                if (this.quoteDiff.price) {
                    paramsToupdate.price = Number(this.quoteDiff.price)
                }
                if (this.quoteDiff.days) {
                    paramsToupdate.days = Number(this.quoteDiff.days)
                }
                if (this.quoteDiff.notes) {
                    paramsToupdate.notes = this.quoteDiff.notes
                }
                const quote = await API.updateQuoteToRequestsItem(
                    this.quote.id,
                    paramsToupdate
                )
                this.$emit('replaceQuote', this.itemId, quote)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style></style>
