<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>{{ createForm ? 'Request A Quote' : 'Update A Quote' }}</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid">
                <v-row>
                    <v-col cols="12">
                        <v-combobox
                            v-model="selectedItems"
                            :hide-no-data="!search"
                            :search-input.sync="search"
                            :items="items"
                            flat
                            chips
                            prepend-icon="mdi-form-select"
                            label="Items"
                            item-value="id"
                            item-text="codeAndDescription"
                            return-object
                            multiple
                            :rules="[rules.required]"
                        >
                            <template v-slot:selection="data">
                                <v-chip
                                    v-bind="data.attrs"
                                    :input-value="data.select"
                                    close
                                    @click="data.select"
                                    @click:close="
                                        data.parent.selectItem(data.item)
                                    "
                                >
                                    {{ data.item.codeAndDescription }}
                                </v-chip>
                            </template>
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-combobox
                            v-model="selectedSuppliers"
                            :hide-no-data="!search"
                            :search-input.sync="search"
                            :items="suppliers"
                            flat
                            chips
                            prepend-icon="mdi-form-select"
                            label="Suppliers"
                            item-value="id"
                            item-text="name"
                            return-object
                            multiple
                            :rules="[rules.required]"
                            @change="updateContacts"
                        >
                            <template v-slot:selection="data">
                                <v-chip
                                    v-bind="data.attrs"
                                    :input-value="data.select"
                                    close
                                    :color="data.item.color"
                                    @click:close="
                                        data.parent.selectItem(data.item)
                                    "
                                    :rules="[rules.required]"
                                >
                                    {{ data.item.name + ' ' }}
                                </v-chip>
                            </template>
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-combobox
                            v-model="selectedContacts"
                            :hide-no-data="!search"
                            :search-input.sync="search"
                            :items="allContactsSelecSuppliers"
                            flat
                            chips
                            prepend-icon="mdi-form-select"
                            label="Contacts"
                            item-value="id"
                            item-text="name"
                            return-object
                            multiple
                            :rules="[rules.required]"
                            @change="onChangeSelectedContacts"
                        >
                            <template v-slot:selection="data">
                                <v-chip
                                    v-bind="data.attrs"
                                    :input-value="data.select"
                                    close
                                    :color="data.item.color"
                                    @click:close="
                                        data.parent.selectItem(data.item)
                                    "
                                >
                                    {{
                                        `${data.item.name} ${
                                            data.item.role
                                                ? '(' + data.item.role + ')'
                                                : ''
                                        }`
                                    }}
                                </v-chip>
                            </template>
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mb-2 mt-n5" ref="notes">
                    <v-col cols="12">
                        <v-textarea
                            v-model="quote.notes"
                            rows="2"
                            hide-details
                            label="Notes"
                            prepend-icon="mdi-text"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                rounded
                color="primary"
                :loading="loading"
                :disabled="!valid && createForm"
                class="mb-5 mt-n2"
                @click="save"
            >
                {{ createForm ? 'SEND QUOTE' : 'UPDATE' }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'
import { objDiff } from '@/helpers/objDiff.js'

export default {
    name: 'AddNewQuote',
    props: {
        quote: {
            type: Object,
            default: () => {},
        },
        originalQuote: {
            type: Object,
            default: () => {},
        },
        purchaseId: {
            type: String,
            default: () => undefined,
        },
        itemId: {
            type: String,
            default: () => undefined,
        },
        createForm: {
            type: Boolean,
            default: () => true,
        },
        contactsByCompany: {
            type: Object,
            default: () => {},
        },
        items: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        loading: false,
        menu: false,
        valid: false,
        suppliers: [],
        allContactsSelecSuppliers: [],
        files: undefined,
        companyId: JSON.parse(localStorage.getItem('company')),
        rules: {
            required: values =>
                (values && values.length > 0) || 'The value is required',
            number: v => v > 0 || 'The value is invalid',
        },
        actualSuppliers: [],
        selectedSuppliers: [],
        actualContacts: [],
        selectedContacts: [],
        search: null,
        selectedItems: [],

        lightColors: [
            '#ABDEE6', // Pastel Pink
            '#CBAACB', // Pastel Peach
            '#FFFFB6', // Pastel Yellow
            '#FEB0C3', // Pastel Mint
            '#C6DBDA', // Pastel Blue
            '#FEE1E8', // Pastel Purple
            '#FED7C3', // Pastel Orange
            '#FFD1DC', // Pastel Rose
            '#AEC6CF', // Pastel Cyan
            '#CFCFC4', // Pastel Gray
            '#FDFD96', // Pastel Lemon
            '#FFDAC1', // Pastel Apricot
            '#E6E6FA', // Pastel Lavender
            '#FFCCCB', // Light Pastel Red
            '#F1CBFF', // Light Pastel Violet
            '#C1E1C1', // Light Pastel Green
            '#D9B3FF', // Soft Pastel Purple
            '#FFB5E8', // Pastel Magenta
            '#FF9AA2', // Pastel Coral
            '#C5E1A5', // Pastel Lime
        ],
    }),
    async mounted() {
        try {
            this.loading = true
            this.suppliers = await API.getSuppliers()
            this.suppliers = this.suppliers.filter(supplier =>
                Object.keys(this.contactsByCompany).some(
                    key => key == supplier.id
                )
            )
            this.items.forEach(item => {
                item.codeAndDescription =
                    item.code && item.description
                        ? `(${item.code}) ${item.description}`
                        : item.description
                        ? `${item.description}`
                        : item.code
                        ? item.code
                        : ''
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        close() {
            this.$emit('closeDialog')
        },
        async save() {
            if (this.createForm) {
                await this.addQuote()
            }
        },
        async addQuote() {
            try {
                this.loading = true
                let contacts = []
                this.selectedContacts.forEach(c =>
                    contacts.push({
                        id: c.id,
                        supplierId: c.supplierId,
                        email: c.email,
                    })
                )
                let suppliers = []
                this.selectedSuppliers.forEach(s =>
                    suppliers.push({ id: s.id, name: s.name })
                )
                const quote = await API.requestQuotes({
                    requestId: this.purchaseId,
                    items: this.selectedItems,
                    suppliers: suppliers,
                    contacts: contacts,
                    notes: this.quote.notes,
                })
                this.$emit('addQuote', quote)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        updateContacts() {
            let differences = objDiff(
                this.actualSuppliers,
                this.selectedSuppliers
            )
            if (
                differences &&
                this.actualSuppliers.length < this.selectedSuppliers.length
            ) {
                differences[0].color = this.lightColors[
                    this.selectedSuppliers.length - 1
                ]
                this.allContactsSelecSuppliers = []
                this.contactsByCompany[differences[0].id].forEach(
                    contact => (contact.color = differences[0].color)
                )

                this.allContactsSelecSuppliers.push(
                    this.contactsByCompany[differences[0].id]
                )
                this.selectedContacts.push(
                    ...this.contactsByCompany[differences[0].id]
                )
                let contactsBase = []
                this.allContactsSelecSuppliers.forEach(registro =>
                    registro.forEach(contact => {
                        contactsBase.push(contact)
                    })
                )
                this.allContactsSelecSuppliers = contactsBase
                this.actualSuppliers = this.selectedSuppliers
                this.actualContacts = this.selectedContacts
            }
            if (this.actualSuppliers.length > this.selectedSuppliers.length) {
                differences = objDiff(
                    this.selectedSuppliers,
                    this.actualSuppliers
                )
                this.allContactsSelecSuppliers = this.allContactsSelecSuppliers.filter(
                    user => user.supplierId != differences[0].id
                )
                this.selectedContacts = this.selectedContacts.filter(
                    user => user.supplierId != differences[0].id
                )
                this.actualContacts = this.selectedContacts

                this.actualSuppliers = this.selectedSuppliers
            }
        },
        onChangeSelectedContacts() {
            let differences = objDiff(
                this.selectedContacts,
                this.actualContacts
            )
            if (
                differences &&
                !this.selectedContacts.find(
                    contact => contact.supplierId == differences[0].supplierId
                )
            ) {
                this.selectedSuppliers = this.selectedSuppliers.filter(
                    supplier => supplier.id !== differences[0].supplierId
                )
                this.actualSuppliers = this.selectedSuppliers
                this.actualContacts = this.selectedContacts
            }
        },
    },
}
</script>

<style></style>
