<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>Purchase Order</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid">
                <v-row no-gutters class="my-4">
                    <v-col cols="12">
                        <v-text-field
                            v-model="purchase.orderNumber"
                            hide-details
                            label="Order Number"
                            prepend-icon="mdi-barcode"
                            :rules="[rules.required]"
                            required
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-4">
                    <v-col cols="12">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="purchase.dueDate"
                                    label="Due Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[rules.required]"
                                    required
                                    hide-details
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="purchase.dueDate"
                                @input="menu = false"
                                :min="min"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-4" ref="notes">
                    <v-col cols="12">
                        <v-textarea
                            v-model="purchase.notes"
                            rows="4"
                            hide-details
                            label="Notes"
                            prepend-icon="mdi-text"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                rounded
                color="primary"
                :loading="loading"
                :disabled="!valid"
                class="mb-5 mt-n2"
                @click="saveOrder"
            >
                {{ scope ? 'SAVE' : 'MOVE TO IN TRANSIT' }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'PurchaseOrder',
    props: {
        request: {
            type: Object,
            default: () => {},
        },
        scope: {
            type: String,
            default: () => undefined,
        },
        item: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        loading: false,
        menu: false,
        valid: false,
        purchase: {},
        rules: {
            required: v => !!v || 'The value is required',
        },
        companyId: JSON.parse(localStorage.getItem('company')),
        min: new Date().toISOString(),
    }),
    mounted() {
        if (this.item && this.item.purchaseOrder) {
            this.purchase.orderNumber = this.item.purchaseOrder.orderNumber
            this.purchase.notes = this.item.purchaseOrder.notes
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        saveOrder() {
            if (this.scope && this.scope == 'item') {
                this.saveOrderPurchase()
            } else {
                this.moveToInTransit()
            }
        },
        async saveOrderPurchase() {
            try {
                this.loading = true
                this.purchase.dueDate = this.purchase.dueDate + 'T07:00:00.000Z'
                const updatedItem = await API.updatePurchaseRequestsItem(
                    this.request.id,
                    this.item.id,
                    {
                        purchaseOrder: {
                            ...this.purchase,
                        },
                    }
                )
                this.$emit('replaceItem', updatedItem)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async moveToInTransit() {
            try {
                this.loading = true
                await API.updatePurchaseRequest(this.request.id, {
                    purchaseOrder: {
                        ...this.purchase,
                    },
                    status: 'inTransit',
                })
                this.$emit('moveToInTransit')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('closeDialog')
        },
    },
}
</script>

<style></style>
